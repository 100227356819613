<script setup lang="ts">
import IconSilverLoader from '@/shared/assets/icons/IconSilverLoader.svg'
import { fixOverlayScroll } from '@/shared/lib/fixOverlayScroll'

const props = defineProps({
  full: {
    type: Boolean,
    default: true,
  },
  background: {
    type: String,
    default: 'white', // 'shadow', 'white'
  },
})

watch(
  () => props.full,
  (isFull) => fixOverlayScroll(isFull),
)

const loaderTypeStyles = computed(() => {
  return {
    'fixed left-0 top-0': props.full,
    'absolute left-0 top-0 w-full h-full': !props.full,
    'bg-black/40': props.background === 'shadow',
    'bg-white': props.background === 'white',
  }
})
</script>

<template>
  <div
    class="rounded-inherit flex h-full w-full items-center justify-center"
    :class="loaderTypeStyles"
  >
    <IconSilverLoader />
  </div>
</template>
